.logOut {
    background: none!important;
    border: none!important;
    cursor: pointer!important;
}

@-webkit-keyframes size-animation {
    from {
        transform: scale(100%);
    }
    to {
        transform: scale(70%);
    }
}
@keyframes size-animation {
    from {
        transform: scale(100%);
    }
    to {
        transform: scale(70%);
    }
}

.rotate-15:hover {
    -webkit-animation: 0.35s ease-in 0s infinite alternate size-animation;
    animation: 0.35s ease-in 0s infinite alternate size-animation;
}
.rotate-15 {
    transition: all ease-in 0.35s;
}