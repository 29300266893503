@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative&family=Alegreya+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');
@font-face {
  font-family: 'GoldenLeafTitle';
  src: url('./assets/fonts/Luxembourg\ Signature.woff2') format('woff2'),
       url('./assets/fonts/Luxembourg\ Signature.woff') format('woff');
}

@media (max-width: 576px) {
  html {
    font-size: 11px!important;
  }
}

html {
  font-size: 13px;
}

a:hover, button:hover, input:hover, textarea:hover, select:hover, .card-profile, .icon-golden, button:not(:disabled), select option:hover{
  cursor: pointer;
}

p, h6  {
  font-family: 'Julius Sans One', sans-serif!important;
  font-size: 1.4rem!important;
  letter-spacing: 0.05rem;
  color: #ffe386;
  text-shadow: rgb(0, 0, 0)2px 2px 2px;
}

h6 {
  font-size: 1.1rem!important;
}

main h1, .titleLoading {
  font-family: 'GoldenLeafTitle', sans-serif;
  font-size: 8rem!important;
  color: rgb(255, 251, 194);
}

.titleLoading {
  line-height: 3.2rem!important;
}

.App {
  text-align: center;
  background-image: url("./assets/img/bg.webp");
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
}

.slider-product {
  position: absolute!important;
  height: auto;
  bottom: 0!important;
  width: 100%!important;
  background-color: transparent!important;
}

.bg-navbar-dark {
  background-color: transparent!important;
}

@media screen and (min-width: 1400px) {
  .col-xxl-3_5 {
    flex-basis: 28.333333%!important;
    max-width: 28.333333%!important;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nav-width-equal {
  width: 30%!important;
  height: 100%;
}

.navbar a > img {
  max-width: max-content!important;
  max-height: max-content!important;
}
.navbar a {
  height: 100%;
}

.blur-5 {
  backdrop-filter: blur(4px);
  box-shadow: -1px 0 13px rgba(0, 0, 0, 0.798)!important;
  background-color: rgba(0, 0, 0, 0.6);
}

.products-home {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

nav > div > img {
  max-width: 70%;
  max-height: 70%;
}

nav > div > p {
  font-size: 1em!important;
  color: 	#ddd18f!important;
  text-shadow: rgb(201, 173, 48) 0px 0px 3px;
  font-family: 'Alegreya Sans SC', sans-serif;
}

.font-title {
  font-size: 2.3rem!important;
  color: 	#cab330!important;
  text-shadow: rgb(224, 205, 119) 0px 0px 1px;
  font-family: 'Alegreya Sans SC', sans-serif;
}
.font-title:hover {
  color: 	#ffffff!important;
}

a > a {
    width: 5.6rem!important;
}

/* .w-adjust {
  width: 90%!important;
}

.row > * {
  max-width: 80%!important;
} */

.main-container {
  height: calc(100vh - 55.89px - 117.26px);
  background-color: transparent!important;
  min-height: calc(100vh - 55.89px - 117.26px);
}
.main-container-home {
  height: calc(100vh - 55.89px - 117.26px);
  background-color: transparent!important;
  min-height: calc(100vh - 55.89px - 117.26px);
}

p {
  font-size: 1.3rem;
  font-family: 'Marcellus', serif;
}

h2, h3, h4, h5 {
    font-family: 'Marcellus', serif;
    font-size: 1.4rem;
    font-weight: bold;
}

p, h1, h2, h3, h4, h5{
  cursor: default;
}
.shadow-text {
    text-shadow: 2px 2px 5px black;
}
input::placeholder, textarea::placeholder {
  color: 	#ddd18f9c!important;
}

.form-select {
  background: none!important;
  padding: 0.375rem 0.55rem 0.375rem 0.55rem!important;
  text-align: center;
  color: #ddd18f!important;
}
.numberItems {
  color: rgb(20, 114, 10);
  font-size: 1.2rem;
  font-weight: bold;
  font-family: 'Marcellus', serif;
}
nav {
  font-family: 'Marcellus', serif!important;
    font-weight: 600;
    height:4.3rem;
}
.img-size-cart {
  width: 5rem;
  height: auto;
}

small {
  color: 	#ddd18f!important;
  letter-spacing: 0.1rem;
}

.nav-link, .nav-link-style {
  font-size: 1.5rem!important;
  color: 	#ddd18f!important;
  letter-spacing: 0.1rem;
  transition: all ease-in 0.2s!important;
}
.nav-link:hover, .nav-link-style:hover {
  color: 	#97893c!important;
  text-shadow: 1px 1px 3px black;
}
.card-profile h5 {
  color: 	#ddd18f!important;
  transition: all ease-in 0.2s!important;
}

.text-golden {
  font-size: 2rem!important;
  color: 	#ffd900!important;
  transition: all ease-in 0.2s!important;
}
.text-golden:hover {
  transform: scale(1.2);
  color: 	#fff3af!important;
}

.grecaptcha-badge {
  display: none!important;
}

.modal {
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
}

.modal-dialog {
  width: 83.33%!important;
}

.icon-golden {
  font-size: 1.5rem!important;
  color: 	rgba(255, 223, 142, 0.979)!important;
  border: none!important;
  transition: all ease-in 0.2s!important;
  background-color: transparent!important;
}

.icon-golden p {
  transition: all ease-in 0.2s!important;
}
.icon-golden:hover {
  color: 	#ffd900!important;
}
.icon-golden:hover p {
  color: 	#14c400!important;
}
.card-profile p {
  color: 	#ffffff!important;
  text-shadow: 1px 1px 3px black;
  font-size: 1.25rem!important;
}

.card-profile .card:hover {
  filter: drop-shadow(2px 2px 15px rgb(255, 223, 16)) invert(1%);
}

.nav-link-style {
  font-size: 1.05rem!important;
  text-decoration: none!important;
}
a > img {
    max-width: 12%;
    max-height: 12%;
}
.bg-weed {
    background-color: rgb(249, 255, 239);
}

::-webkit-scrollbar-thumb {
  background-color: gold!important;
}

::-webkit-scrollbar-track {
  background-color: rgba(0,0,0,0.1)!important;
}

/* .products-overflow{
  overflow-y: scroll!important;
  height: calc(65vh)!important;
} */

h1 {
    font-size: 2.85rem!important;
    font-family: 'Alegreya Sans SC', sans-serif;
}

main.container-fluid {
    vertical-align: middle;
}
.test {
  white-space: nowrap!important;
}
.container {
  max-height: 800vh!important;
}
.navbar {
  flex-wrap: nowrap!important;
}
.card {
  border-radius: 10%!important;
  border: none!important;
  background: rgb(9,8,0)!important;
  background: radial-gradient(circle, rgba(238, 206, 103, 0.15) 50%, rgba(255, 200, 21, 0.00) 100%)!important;
}
.card-text.price{
  font-size: 2rem!important;
  font-weight: 400;
  margin: 0!important;
}
.card-title {
  font-size: 2.5rem!important;
  font-family: "Caveat", cursive;
  font-optical-sizing: auto;
  font-style: normal;
  color: #fff7c7f6!important;
  text-shadow: 1px 1px 8px rgb(0, 0, 0)!important;
}
.user-card-title {
  font-family: "Marcellus", serif!important;
  font-size: 1.8rem!important;
}
.card-text {
  font-size: 1.1rem!important;
  font-weight: 500;
}

/*Start Button Style*/
.button-64 {
    font-family: 'Alegreya Sans SC', sans-serif;
    align-items: center;
    border: 0;
    border-radius: 8px;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    font-size: 20px;
    justify-content: center;
    line-height: 1em;
    max-width: 100%;
    min-width: 140px;
    padding: 3px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
}

.button-64:active,
.button-64:hover {
  outline: 0;
  color: #000000;
}

.button-64 span {
  background-color: rgb(5, 6, 45);
  padding: 6px 24px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}
form .button-64 span, .home-btn .button-64 span {
  background-color: rgba(255, 255, 255, 0.616);
  padding: 8px 18px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
  color: #000000!important;
  font-weight: 700;
  background-image: linear-gradient(to bottom, rgb(245, 238, 197), goldenrod);
}
form .button-64, .home-btn .button-64 {
  background-image: linear-gradient(to bottom, gold, goldenrod);
}
.title-loading {
  font-size: 2rem!important;
  color: 	#ddd18f!important;
  text-shadow: rgb(201, 173, 48) 0px 0px 3px;
  font-family: 'Alegreya Sans SC', sans-serif;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-359deg);
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.button-64:hover span {
  background: none;
}
.bg-loading {
  background-color: #0c0c0c!important;
}
@media (min-width: 768px) {
  .button-64 {
    font-size: 24px;
    min-width: 196px;
  }
}
.b-green {
    background-image: linear-gradient(to bottom, #3bb78f, #0bab64);
}
.b-purple {
    background: linear-gradient(to bottom, #7f53ac, #647dee);
}
.b-green:hover {
    box-shadow: rgba(81, 252, 65, 0.2) -6px 7px 15px -4px!important;
}
.b-purple:hover {
    box-shadow: rgba(152, 65, 252, 0.534) -6px 7px 15px -4px!important;
}
/*End Button Style*/
.disabled {
    color: 	#857936!important;
}
input, input:autofill, textarea, textarea:autofill {
  background-color: rgba(253, 233, 182, 0.015)!important;
  border: none!important;
  border-bottom: 2px solid goldenrod!important;
  transition: all ease-in 0.2s;
  color: rgb(255, 244, 215)!important;
}
input:focus, textarea:focus {
  caret-color: goldenrod;
  border: none!important;
  background-color: transparent!important;
  box-shadow: none!important;
  border-bottom: 2px solid rgb(77, 56, 0)!important;
  outline: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffe7b2;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
    font-weight: 500;
}
.validation-p {
  font-size: 1.3rem;
  word-wrap: break-word;
}
.form-select {
  background-color: transparent!important;
  border: 1px solid goldenrod!important;
  color: rgb(124, 124, 124)!important;
}
#dropdown-btn {
  background-color: transparent!important;
  border: 1px solid rgba(241, 204, 110, 0.658)!important;
  font-size: 1.7rem;
  font-weight: 500!important;
  font-family: 'Marcellus', serif!important;
  padding: 0px 20px;
  transition: all ease-in 0.25s;
}
#dropdown-btn:hover {
  background-color: rgb(255, 234, 180)!important;
  text-shadow: 0px 0px 5px black;
  color: rgb(63, 63, 63);
  border: none;
}
.dropdown-menu{
  background-color: rgba(0, 0, 0, 0.61)!important;
}

.modal-backdrop.show {
  opacity: 0.45!important;
}

.profile-card .card p, .profile-card .card h5, .pointer:hover{
  cursor: pointer!important;
}

.pointer, .pointer:hover {
  transition: all ease-in 0.15s;
}

.modal-body {
  background: radial-gradient(circle, rgba(238, 206, 103, 0.02) 50%, rgba(252, 213, 84, 0.1) 100%)!important;
  margin: 1rem;
  border-radius: 15px;
}

.modal-body h4 {
  font-size: 2.2rem;
  font-family: "Caveat", cursive;
  font-optical-sizing: auto;
  font-style: normal;
  color: #fff7c7f6!important;
  text-shadow: 1px 1px 8px rgb(0, 0, 0)!important;
}

.modal-footer {
  border-top: none!important;
}
.modal-header {
  border-bottom: none!important;
}

.title-white {
  font-size: 2rem!important;
  color: 	#f3f3f3!important;
  text-shadow: rgb(102, 102, 102) 0px 0px 10px;
  font-family: 'Alegreya Sans SC', sans-serif;
}

.btn-modal-size {
  font-size: 3.2rem!important;
  background-color: transparent!important;
  border: none!important;
}

.dropdown-item {
  color: whitesmoke!important;
  font-family: 'Marcellus', serif!important;
  font-size: 1.2rem;
  font-weight: 600!important;
  letter-spacing: 0.1rem;
}

.modal-btn-gold {
  background-color: transparent!important;
  border: none!important;
  font-size: 1.35rem!important;
}

.dropdown-item:hover {
  background-color: #ddd18f!important;
  color: rgb(0, 0, 0)!important;
}
.dropdown-item.active {
  background-color: #817113!important;
  font-size: 1.2rem;
}
.no-css-btn {
  background-color: transparent!important;
  border: 1px solid rgba(255, 223, 142, 0.979)!important;
  border-radius: 10px;
  padding: 5px 10.8px;
  width: 5rem;
  height: 3.5rem;
}

.disabled_product {
  filter: grayscale(100%)!important;
}

.no-css-btn > .fa-cart-shopping {
  color: rgba(255, 223, 142, 0.979)!important;
  font-size: 2.3rem;
  transition: all ease-in 0.25s;
}
.no-css-btn > .fa-cart-shopping:hover {
  color: white!important;
}

.btn-goldenleaf {
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  font-weight: 700;
  font-size: 15px;
  background: linear-gradient(90deg, rgba(203,200,92,0.8) 0%, rgba(174,137,0,0.8) 45%);
  padding: 10px 35px;
  margin: 0 auto;
  box-shadow: 0 5px 15px rgba(0,0,0,0.20);
}

.btn-goldenleaf span {
  position: relative;
  font-size: 1.7rem;
  z-index: 1;
  color: #000000f7!important;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  font-family: 'Alegreya Sans SC', sans-serif;
}
.btn-goldenleaf:hover > span {
  color: rgb(210, 205, 50)!important;
  font-weight: bold;
}
.btn-goldenleaf:hover {
  filter: brightness(1);
}

a {
  text-decoration: none!important;
}

.btn-goldenleaf:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 442%;
  width: 340%;
  background: rgb(10, 10, 10)!important;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  -webkit-transform: translateX(-98%) translateY(-25%) rotate(90deg);
  transform: translateX(-110%) translateY(-55%) rotate(90deg);
}

.btn-goldenleaf:hover:after {
  -webkit-transform: translateX(-9%) translateY(-25%) rotate(90deg);
  transform: translateX(-35.2%) translateY(-5%) rotate(90deg);
}